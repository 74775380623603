var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-form",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-success px-5",
                  attrs: { type: "button" },
                  on: { click: _vm.submeter }
                },
                [_vm._v("Atualizar senha")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("f-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{ required: true }"
              }
            ],
            ref: "novaSenha",
            attrs: {
              name: "novaSenha",
              type: "password",
              "data-vv-as": "Nova Senha",
              label: "Sua nova senha",
              modelErrors: _vm.errors,
              cols: 3
            },
            model: {
              value: _vm.model.novaSenha,
              callback: function($$v) {
                _vm.$set(_vm.model, "novaSenha", $$v)
              },
              expression: "model.novaSenha"
            }
          }),
          _c("f-input", {
            directives: [
              {
                name: "id",
                rawName: "v-id",
                value: _vm.model.novaSenha,
                expression: "model.novaSenha"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{ required: true }"
              }
            ],
            attrs: {
              name: "confirmacaoSenha",
              type: "password",
              "data-vv-as": "novaSenha",
              label: "Confirme sua senha",
              modelErrors: _vm.errors,
              cols: 3
            },
            model: {
              value: _vm.model.confirmacaoSenha,
              callback: function($$v) {
                _vm.$set(_vm.model, "confirmacaoSenha", $$v)
              },
              expression: "model.confirmacaoSenha"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }