<template>
  <card-form>
    <div class="row">
      <f-input 
        name="novaSenha"
        ref="novaSenha"
        type="password"
        v-model="model.novaSenha" 
        v-validate="{ required: true }"
        data-vv-as="Nova Senha"
        label="Sua nova senha"
        :modelErrors="errors"
        :cols="3"
      />
      <f-input 
        v-id="model.novaSenha" 
        name="confirmacaoSenha"
        type="password"
        v-model="model.confirmacaoSenha" 
        v-validate="{ required: true }"
        data-vv-as="novaSenha"
        label="Confirme sua senha"
        :modelErrors="errors"
        :cols="3"
      />
    </div>

    <template #footer>
      <button class="btn btn-success px-5" @click="submeter" type="button">Atualizar senha</button>
    </template>

  </card-form>

</template>

<script>
import CardForm from "@/components/Ui/CardForm";
import { atualizarSenha } from "@/services/usuario.service";
import { trataErro, trataUpdateSucesso } from "../../../../utils/tratar.retornos";
import { messageError } from '@/utils/messages'

export default {
  components: {
    CardForm,
  },
  data() {
    return {
      model: {
        novaSenha: '',
        confirmacaoSenha: '',
      },
    };
  },
  methods: {
    async submeter(event) {
      event.preventDefault()
      var isValid = await this.$validator.validateAll();
      if (isValid) {
        // TODO: Não consegui usar o "confirmed" do vee-validate no f-input
        if (this.model.novaSenha == this.model.confirmacaoSenha) {
          atualizarSenha(this.model.novaSenha)
            .then((response) => {
              if (response) {
                trataUpdateSucesso(this, 'home')
              } else {
                messageError(this, 'A senha não pôde ser salva.');
              }
            })
            .catch((e) => 
              trataErro(e, this)
            )
        } else {
          messageError(this, 'A confirmação deve ser igual à nova senha.');
        }
      }
    },
  },
};
</script>